/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Card, Flex, Textarea, Title } from '@tremor/react'
import { useState } from 'react'
import MarkdownPreview from '@uiw/react-markdown-preview'
import { useApiV1AssistCreate } from '../../api/api.gen'
import { EntitiesMode } from '../../api/api'

export default function HomePage() {
    const modes = [
        'Translate from Arabic to English',
        'Translate from English to Arabic',
        'Summarize',
        'Proofread',
    ]
    const [mode, setMode] = useState(modes[0])
    const [input, setInput] = useState('')
    const { isLoading, isExecuted, response, sendNowWithParams } =
        useApiV1AssistCreate({}, {}, false)
    const apiMode = () => {
        switch (mode) {
            case 'Translate from Arabic to English':
                return EntitiesMode.ModeTranslateArabicEnglish
            case 'Translate from English to Arabic':
                return EntitiesMode.ModeTranslateEnglishArabic
            case 'Summarize':
                return EntitiesMode.ModeSummarize
            case 'Proofread':
                return EntitiesMode.ModeProofread
            default:
                return EntitiesMode.ModeProofread
        }
    }

    return (
        <div className="min-h-screen flex flex-col md:flex-row lg:flex-row bg-gray-50">
            {/* Left Sidebar */}
            <Flex
                flexDirection="col"
                justifyContent="start"
                alignItems="start"
                className="w-full md:w-1/4 lg:w-1/4 bg-gray-100 p-4 space-y-2"
            >
                <div className="font-bold text-lg mb-4">Watad GPT</div>
                {modes.map((m) => (
                    <Button
                        variant="light"
                        className={`w-full text-start text-gray-600 cursor-pointer  p-2 ${
                            mode === m ? 'bg-gray-200 rounded' : ''
                        }`}
                        onClick={() => setMode(m)}
                    >
                        {m}
                    </Button>
                ))}
            </Flex>

            {/* Main Content */}
            <Flex flexDirection="col" className="w-full pt-6">
                <Flex
                    justifyContent="center"
                    className="items-center mg:items-start lg:items-start flex-grow flex-col md:flex-row lg:flex-row md:space-x-4 lg:space-x-4"
                >
                    <Card className="w-full max-w-xl p-8 bg-white shadow-lg rounded-lg space-y-2 mb-4">
                        <Title className="text-3xl font-bold mb-4 text-center">
                            {mode}
                        </Title>

                        <Textarea
                            rows={10}
                            value={input}
                            onValueChange={setInput}
                            className={
                                apiMode() ===
                                EntitiesMode.ModeTranslateArabicEnglish
                                    ? 'text-right'
                                    : 'text-left'
                            }
                            dir={
                                apiMode() ===
                                EntitiesMode.ModeTranslateArabicEnglish
                                    ? 'rtl'
                                    : 'ltr'
                            }
                        />
                        <Button
                            loading={isExecuted && isLoading}
                            disabled={isExecuted && isLoading}
                            onClick={() =>
                                sendNowWithParams(
                                    { mode: apiMode(), input },
                                    {}
                                )
                            }
                        >
                            Submit
                        </Button>
                    </Card>
                    <Card className="w-full max-w-xl p-8 bg-white shadow-lg rounded-lg">
                        <Title>Output</Title>
                        <div>
                            <MarkdownPreview
                                source={response?.output}
                                className="!bg-transparent"
                                style={{
                                    direction:
                                        apiMode() ===
                                        EntitiesMode.ModeTranslateEnglishArabic
                                            ? 'rtl'
                                            : 'ltr',
                                    textAlign:
                                        apiMode() ===
                                        EntitiesMode.ModeTranslateEnglishArabic
                                            ? 'right'
                                            : 'left',
                                }}
                                wrapperElement={{
                                    'data-color-mode': 'light',
                                }}
                            />
                        </div>
                    </Card>
                </Flex>
            </Flex>
        </div>
    )
}
