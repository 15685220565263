import { ArrowPathIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { Button, Card, Flex, Text, Title } from '@tremor/react'
import Router from './router'
import Spinner from './components/Spinner'
import { getAuthHeader, setAuthHeader } from './api/ApiConfig'
import { useAuth } from './utilities/auth'
import './App.css'

dayjs.extend(utc)

export default function App() {
    useEffect(() => {
        setAuthHeader('ABCD')
    }, [])
    return <Router />
}
