import { Route, Routes } from 'react-router-dom'
import HomePage from '../page/home'

const authRoutes = [
    {
        path: '/',
        element: <HomePage />,
    },
]

export default function Router() {
    return (
        <Routes>
            {authRoutes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                />
            ))}
        </Routes>
    )
}
