import axios from 'axios'

const { origin, hostname } = window.location
export const apiHostname = () => {
    switch (hostname) {
        case 'localhost':
        case '127.0.0.1':
            return 'http://localhost:8080'
        default:
            return origin
    }
}
export const authHostname = () => {
    switch (hostname) {
        case 'localhost':
        case '127.0.0.1':
            return 'http://localhost:8080'
        default:
            return origin
    }
}
const instance = axios.create({
    baseURL: apiHostname(),
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        common: {
            Authorization:
                localStorage.getItem('token') === null
                    ? undefined
                    : `Bearer ${localStorage.getItem('token')}`,
        },
    },
})

export const setAuthHeader = (authToken?: string) => {
    instance.defaults.headers.common.Authorization = `Bearer ${authToken}`
}

export const getAuthHeader = () => {
    return instance.defaults.headers.common.Authorization
}

export const isLoggedIn = () => {
    return instance.defaults.headers.common.Authorization
}

export default instance
